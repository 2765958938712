<template>
  <div class="Table">
    <div class="table-responsive">
      <!--  table toolbar  -->
      <div class="Table__toolbar">
        <div class="Table__toolbar-label">
          <div class="font-size-16">費用情報</div>
        </div>
        <div class="Table__toolbar-buttons">
          <slot name="right" />
          <ToolTip content="このSaaSでは請求情報自動取得が出来ません。" :min-width="300">
            <b-button variant="light" class="m-1" :disabled="true">請求情報自動取得</b-button>
          </ToolTip>
          <b-button variant="light" class="m-1" @click="getCostManually">請求情報取得</b-button>
        </div>
      </div>
      <!--    table content  -->
      <div class="table-responsive">
        <b-table
          ref="Contract-table"
          :items="items"
          :fields="fields"
          sort-by="date"
          thead-class="Table__header"
          tbody-tr-class="Table__row"
          hover
        >
          <template #head()="data">
            <div v-if="!!data.label" class="header">
              <div class="header--value">
                {{ data.label }}
              </div>
              <div v-if="data.field.sortable" class="header--sort">
                <SortIcon />
              </div>
            </div>
            <div v-else class="action">
              {{ '' }}
            </div>
          </template>
          <template #cell(date)="data">
            {{ convertStringToDate(data.item.date) }}
          </template>
          <template #cell(price)="data">
            {{ data.value + '円' }}
          </template>
          <template #cell(cost_type)="data">
            {{ getCostTypeLabel(data.value) }}
          </template>
          <template #cell(contract_price)="data">
            {{ getContractPriceLabel(data.item) }}
          </template>
        </b-table>
      </div>
      <!--    dialog   -->
      <slot name="dialog" />
    </div>
  </div>
</template>

<script>
import SortIcon from '@/components/sort-icon'
import { saasCommonService } from '@/services/saas-service/saas-common-service'
import ToolTip from '@/components/commons/common-tooltips'
import { stringToDateJapan } from '@/utils/date-utils'
import { mapActions, mapGetters } from 'vuex'
import { ALERT_TYPE } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'

export default {
  name: 'ExpenseInfomationTable',
  components: { SortIcon, ToolTip },
  props: {
    selectedDate: {
      type: String,
      default: '',
    },
    refresh: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      fields: saasCommonService.getExpensiveFields(),
      cacheRefresh: 1, // 0 : not fresh 1: refresh data
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['mt_contract_type', 'mt_cost_type']),
    ...mapGetters('saas', ['service', 'fetchTime']),
    month() {
      return this.selectedDate.split('-')[0]
    },
    year() {
      return this.selectedDate.split('-')[1]
    },
  },
  watch: {
    async refresh() {
      if (this.service.id) {
        this.items = await saasCommonService.getSaaSContractCosts(
          this.service.id,
          this.month,
          this.year,
          0,
          true
        )
      }
    },
  },
  async mounted() {
    let month = ''
    let year = ''
    if (this.fetchTime[this.service.id]) {
      month = Number(this.fetchTime[this.service.id].month)
      year = Number(this.fetchTime[this.service.id].year)
    }
    this.items = await saasCommonService.getSaaSContractCosts(this.service.id, month, year)
  },
  methods: {
    ...mapActions('alert', ['displayAlert']),
    convertStringToDate(value) {
      return stringToDateJapan(value)
    },
    getCostTypeLabel(value) {
      return this.mt_cost_type.find((item) => item.id === value).name
    },
    getContractPriceLabel(rowData) {
      if (rowData.contract_type === this.mt_contract_type[0].id) {
        return `${rowData.contract_price}円/一ヶ月`
      }
      if (rowData.contract_type === this.mt_contract_type[1].id) {
        return `${rowData.contract_price}円/一ユーザ`
      }
    },
    async getCostManually() {
      this.items = await saasCommonService.getSaaSContractCosts(
        this.service.id,
        this.month,
        this.year,
        this.cacheRefresh,
        true
      )
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.SAAS_CONNECT.LK09,
      })
    },
  },
}
</script>
