<template>
  <div class="MTTooltips">
    <div @mouseenter="showTooltip" @mouseleave="hideTooltip">
      <slot />
      <div
        ref="parent"
        :class="`MTTooltips--content arrow-${generatedPosition}`"
        :style="{ 'min-width': minWidth + 'px', left: left + 'px' }"
      >
        <span ref="content" :style="style" :class="`arrow left-${arrowLeft}`">{{ content }} </span>
      </div>
    </div>
  </div>
</template>
<script>
/*
 *  MT Tooltips components
 *
 * */
export default {
  name: 'MTTooltips',
  props: {
    position: {
      type: String,
      default: 'bottom', // bottom or top only
    },
    content: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: Number,
      default: 50,
    },
    left: {
      type: [Number, String],
      default: '',
    },
    arrowLeft: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      show: false,
      bottomValue: 0,
    }
  },
  computed: {
    style() {
      return {
        opacity: this.show && !this.disabled ? 1 : 0,
      }
    },
    generatedPosition() {
      if (this.position === 'top') {
        return this.position
      }
      return 'bottom'
    },
  },
  methods: {
    showTooltip() {
      if (this.position === 'top') {
        const parent = this.$refs['parent']
        const contentHeight = this.$refs['content'].clientHeight
        parent.style.top = `calc(-100% - ${contentHeight - 28}px)` // 28 = padding-top + padding-bottom + font-size
      }
      this.show = true
    },
    hideTooltip() {
      this.show = false
    },
  },
}
</script>
<style lang="scss">
$arrowPaddingSize: 8px;
@mixin tooltipsParent() {
  z-index: 10;
  position: absolute;
  transform: translate(-50%, 0);
  left: calc(50%);
  width: fit-content;
  max-width: 200px;
  opacity: 0.75;
  text-align: center;
}

@mixin tooltipsContent() {
  display: inline-block;
  background-color: black;
  color: white;
  opacity: 0.75;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  transition: all 0.3s ease;
  transition-delay: 0.2s;
}

@mixin arrowContent($left: 50%) {
  position: absolute;
  content: '';
  left: calc(#{$left} - 8px);
  width: 0;
  height: 0;
  border-left: $arrowPaddingSize solid transparent;
  border-right: $arrowPaddingSize solid transparent;
  border-top: $arrowPaddingSize solid black;
}
.MTTooltips {
  position: relative;
  display: inline-block;
  &--content.arrow-top {
    @include tooltipsParent();
    top: calc(-100% - 8px);
    span {
      @include tooltipsContent();
      margin-bottom: $arrowPaddingSize;
      &:before {
        @include arrowContent();
        bottom: 0;
      }
    }
  }
  &--content.arrow-bottom {
    @include tooltipsParent();
    span {
      @include tooltipsContent();
      margin-top: $arrowPaddingSize;
      &:before {
        @include arrowContent();
        top: 0;
        transform: rotate(180deg);
      }
    }
  }
  @for $i from -100 through 100 {
    span.arrow.left-#{$i} {
      &:before {
        @include arrowContent('#{$i}%');
        top: 0;
        transform: rotate(180deg);
      }
    }
  }
}
</style>
