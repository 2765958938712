<template>
  <div class="d-flex float-end">
    <b-form-select
      v-model="selectedMonth"
      size="sm"
      class="form-select m-1 select-custom"
      :options="monthOptions"
      @change="handleDateChange"
    />
    <b-form-select
      v-model="selectedYear"
      size="sm"
      class="form-select m-1 select-custom"
      :options="yearOptions"
      @change="handleDateChange"
    />
  </div>
</template>

<script>
import { saasCommonService } from '@/services/saas-service/saas-common-service'
import { mapGetters } from 'vuex'

export default {
  name: 'SelectTime',
  props: {
    month: {
      type: String,
      required: true,
    },
    year: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedMonth: '',
      selectedYear: '',
      monthOptions: saasCommonService.getMonths(),
      yearOptions: saasCommonService.getYears(),
    }
  },
  computed: {
    ...mapGetters('saas', ['fetchTime', 'service']),
  },
  mounted() {
    if (this.fetchTime[this.service.id]) {
      this.selectedMonth = this.fetchTime[this.service.id].month + '月'
      this.selectedYear = this.fetchTime[this.service.id].year + '年'
    } else {
      this.selectedMonth = new Date().getMonth() + 1 + '月'
      this.selectedYear = new Date().getFullYear() + '年'
    }
    this.handleDateChange() // init
  },
  methods: {
    handleDateChange() {
      this.$emit('update:month', this.selectedMonth.split('月')[0])
      this.$emit('update:year', this.selectedYear.split('年')[0])
    },
  },
}
</script>

<style lang="scss">
.select-custom {
  width: 120px;
}
</style>
