import axios from '@/configs/http/internal-api-axios'
import { getYearsOptions } from '@/utils/date-utils'
import { extendMasterRead } from '@/utils/function-utils'
import { get } from 'lodash-es'

function getSaaSAddModalFields() {
  return [
    { key: 'checkbox', label: '', sortable: false },
    {
      key: 'user_code',
      label: '従業員ID',
      sortable: true,
    },
    {
      key: 'name',
      label: '従業員名',
      sortable: true,
    },
    {
      key: 'email',
      label: 'メールアドレス',
      sortable: true,
    },
    {
      key: 'department_name',
      label: '部署',
      sortable: true,
    },
    {
      key: 'position_name',
      label: '役職',
      sortable: true,
    },
    {
      key: 'use_app',
      label: '利用中のサービス数',
      sortable: true,
    },
    {
      key: 'status',
      label: 'ステータス',
      sortable: true,
    },
  ]
}

function getSaaSRemoveModalFields() {
  return [
    { key: 'checkbox', label: '', sortable: false },
    { key: 'external_id', label: 'アカウントID', sortable: true },
    { key: 'name', label: 'ユーザー名', sortable: true },
    { key: 'email', label: 'メールアドレス', sortable: true },
    { key: 'user_code', label: '従業員ID', sortable: true },
    { key: 'employee', label: '従業員', sortable: true },
    { key: 'department_name', label: '部署', sortable: true },
    { key: 'created_at', label: '連携日時', sortable: true },
    { key: 'status', label: 'ステータス', sortable: true },
  ]
}

function getMonths() {
  return ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
}

function getYears() {
  return getYearsOptions().map((item) => {
    return item + '年'
  })
}

function getLinkingFields() {
  return [
    {
      key: 'user_code',
      label: '従業員ID',
      sortable: true,
    },
    {
      key: 'name',
      label: '従業員名',
      sortable: true,
    },
    {
      key: 'email',
      label: 'メールアドレス',
      sortable: true,
    },
    {
      key: 'department_name',
      label: '部署',
      sortable: true,
    },
    {
      key: 'position_name',
      label: '役職',
      sortable: true,
    },
    {
      key: 'use_app',
      label: '利用中のサービス数',
      sortable: true,
    },
    {
      key: 'status',
      label: 'ステータス',
      sortable: true,
    },
  ]
}

function getServiceFields() {
  return [
    { key: 'external_id', label: 'アカウントID', sortable: true },
    { key: 'name', label: 'ユーザー名', sortable: true },
    { key: 'email', label: 'メールアドレス', sortable: true },
    { key: 'integrate_info_user_code', label: '従業員ID', sortable: true },
    { key: 'integrate_info_name', label: '従業員', sortable: true },
    { key: 'integrate_info_department_name', label: '部署', sortable: true },
    { key: 'created_at', label: '連携日時', sortable: true },
    { key: 'linking', label: '連携方法', sortable: true },
    { key: 'integrate_info_status', label: 'ステータス', sortable: true },
  ]
}
function getContractFields() {
  return [
    { key: 'code', label: '契約ID', sortable: true },
    { key: 'name', label: '契約プラン', sortable: true },
    { key: 'app_name', label: 'サービス名', sortable: true },
    { key: 'type', label: '契約サイクル', sortable: true },
    { key: 'price', label: '金額', sortable: true },
    { key: 'start_date', label: '契約開始月', sortable: true },
    { key: 'end_date', label: '契約終了月', sortable: true },
  ]
}
function getExpensiveFields() {
  return [
    { key: 'date', label: '費用情報取得日時', sortable: true },
    { key: 'price', label: '金額', sortable: true },
    { key: 'cost_type', label: '費用情報取得方法', sortable: true },
    { key: 'contract_price', label: '単価', sortable: true },
    { key: 'subscriber_users', label: 'アカウント数', sortable: true },
  ]
}

async function getDetailSaas(saasId, isMaster = false) {
  if (!saasId) {
    return
  }
  let url = `api/v1/apps/${saasId}`
  url = extendMasterRead(isMaster, url, '?')
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return response.data
}

async function connectApp(saasId) {
  if (!saasId) {
    return
  }
  const url = `api/v1/service/connect`
  return axios
    .post(url, { app_id: saasId })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return {
        success: false,
        messages: get(err, 'response.data.message.error', ''),
      }
    })
}

async function disconnectApp(saasId) {
  if (!saasId) {
    return
  }
  const url = `api/v1/service/disconnect`
  return axios.post(url, { app_id: saasId }).then((res) => {
    return res.data
  })
}

async function addToken(saasId, meta_data, isMaster = true) {
  if (!saasId) {
    return
  }
  let url = `api/v1/service/token`
  url = extendMasterRead(isMaster, url, '?')
  const response = await axios.post(url, { app_id: saasId, meta_data }).then((res) => {
    return res.data
  })
  return response.data
}

async function getListUserBySaas(
  saasId,
  month,
  year,
  currentPage,
  perPage,
  keyword = '',
  isMaster = false
) {
  let url = `api/v1/service/${saasId}/users?keyword=${keyword}`
  if (month && year) {
    url += `&month=${month}&year=${year}`
  }
  if (currentPage && perPage) {
    url += `&currentPage=${currentPage}&perPage=${perPage}`
  }
  url = extendMasterRead(isMaster, url)
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data.items,
    total: response.data.total,
  }
}

async function getListIntegrateUsers(saasId, user, currentPage, perPage, keyword = '') {
  if (!user) {
    user = 1 // pick a random number
  }
  let url = `api/v1/service/${saasId}/${user}/integrate?keyword=${keyword}`
  if (currentPage && perPage) {
    url += `&currentPage=${currentPage}&perPage=${perPage}`
  }
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data.items,
    total: response.data.total,
  }
}

async function integrateUser(subcribeAppId, saasUserId, intergrateUserId) {
  if (!intergrateUserId) {
    return
  }
  const url = `api/v1/service/${subcribeAppId}/${saasUserId}/integrate`
  return axios.post(url, { user_id: intergrateUserId }).then((res) => {
    return res.data
  })
}

async function removeIntegrateUser(subcribeAppId, removedId) {
  const url = `api/v1/service/${subcribeAppId}/${removedId}/integrate`
  const response = await axios.delete(url).then((res) => {
    return res.data
  })
  return response.data
}

async function getSaaSContract(saasId, month, year, isMaster = true) {
  if (!saasId || !month || !year) {
    return []
  }
  let url = `api/v1/service/${saasId}/contracts?month=${month}&year=${year}`
  url = extendMasterRead(isMaster, url)
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return response.data
}

async function getSaaSContractCosts(saasId, month, year, cache_refresh = 0, isMaster = false) {
  let url = ''
  if (!saasId) {
    return []
  }
  if (month && year) {
    url = `api/v1/service/${saasId}/cost?month=${month}&year=${year}&cache_refresh=${cache_refresh}`
    url = extendMasterRead(isMaster, url)
  } else {
    url = `api/v1/service/${saasId}/cost`
    url = extendMasterRead(isMaster, url, '?')
  }
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return response.data.items
}

async function getSavedMetadata(saasUserId, isMaster = false) {
  let url = `api/v1/service/token?app_id=${saasUserId}`
  url = extendMasterRead(isMaster, url)
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return response.data
}

async function getSaaSConnected() {
  const url = `api/v1/service/app/connected?master=1`
  return axios.get(url).then((res) => {
    return res.data
  })
}

export const saasCommonService = {
  getSaaSAddModalFields,
  getSaaSRemoveModalFields,
  getMonths,
  getYears,
  getServiceFields,
  getContractFields,
  getExpensiveFields,
  getDetailSaas,
  connectApp,
  disconnectApp,
  addToken,
  getListUserBySaas,
  getListIntegrateUsers,
  integrateUser,
  removeIntegrateUser,
  getSaaSContract,
  getSaaSContractCosts,
  getSavedMetadata,
  getLinkingFields,
  getSaaSConnected,
}
