<template>
  <div class="SaaSLayout">
    <page-header :title="pageTitles" :items="breadCrumbItems" />
    <div class="SaaSLayout__connectionInfo">
      <slot name="connection-info" />
    </div>
    <div v-if="connected" class="SaaSLayout__connected">
      <slot name="connected-content" />
    </div>
  </div>
</template>
<script>
import PageHeader from '@/layouts/vertical/page-header'

export default {
  name: 'SaaSLayout',
  components: { PageHeader },
  props: {
    pageTitles: {
      type: String,
      required: true,
    },
    breadCrumbItems: {
      type: Array,
      default: () => [],
    },
    connected: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss">
.SaaSLayout {
  .btn-light {
    color: #343a40;
    border: 2px solid #adb5bd;
    &:hover {
      color: #f5f5f5;
      background-color: #adb5bd;
      border-color: #adb5bd;
    }
    &:disabled {
      background-color: #eff2f7;
      border-color: #eff2f7;
    }
  }
}
</style>
