<template>
  <c-modal
    :id="dialogId"
    :title="dialogTitle"
    :buttons="isInvite ? dialogInviteButtons : dialogConnectButtons"
    size="xl"
    dialog-class="InviteModal"
    :handle-hide-modal="resetValue"
  >
    <div class="Table">
      <div class="Table__toolbar">
        <div class="Table__toolbar-search">
          <search-input :value.sync="searchInput" />
          <slot name="left" />
        </div>
      </div>

      <div class="table-responsive">
        <b-table
          ref="dl-table"
          :items="items"
          :fields="fields"
          sort-by="user_code"
          thead-class="Table__header InviteModal__header"
          :tbody-tr-class="rowClass"
          hover
          select-mode="single"
          selectable
          @row-selected="selectedRow"
        >
          <template #head()="data">
            <div v-if="!!data.label" class="header">
              <div class="header--value">
                <span>
                  {{ data.label }}
                </span>
              </div>
              <div v-if="data.field.sortable" class="header--sort">
                <SortIcon />
              </div>
            </div>
          </template>
          <template #cell(status)="data">
            <div>
              <div :class="checkClass(data.value)">
                {{ getLabelStatus(data.value) }}
              </div>
            </div>
          </template>
        </b-table>
      </div>
      <div class="Table__paging">
        <table-paging
          :current-page.sync="currentPage"
          :per-page.sync="perPage"
          :total="total"
          :options-paging="optionsPaging"
        />
      </div>
    </div>
  </c-modal>
</template>

<script>
import SearchInput from '@/components/commons/common-search-input'
import CModal from '../commons/common-modal'
import TablePaging from '@/components/commons/common-table-paging'
import SortIcon from '@/components/sort-icon'
import { ALERT_TYPE, MEMBER_STATUS, USER_STATUS } from '@/utils/constants'
import { mapActions, mapGetters } from 'vuex'
import { saasCommonService } from '@/services/saas-service/saas-common-service'
import { MESSAGES } from '@/utils/messages'
import get from 'lodash-es/get'

export default {
  name: 'SaasInviteIntegrateModal',
  components: { CModal, SearchInput, TablePaging, SortIcon },
  props: {
    dialogId: {
      type: String,
      required: true,
    },
    isInvite: {
      type: Boolean,
      default: true,
    },
    reloadFunc: {
      type: Function,
      default: () => {
        return {}
      },
    },
    reload: {
      type: Boolean,
      default: false,
    },
    selectedSaasId: {
      type: Number,
      default: 0,
    },
    refreshAccountList: {
      type: Function,
      default: () => {
        return {}
      },
    },
    inviteFunc: {
      type: Function,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      searchInput: '',
      currentPage: 1,
      perPage: 10,
      optionsPaging: [10, 50, 100],
      status: [
        { id: 1, text: '利用中' },
        { id: 2, text: '停止' },
      ],
      items: [],
      fields: saasCommonService.getLinkingFields(),
      total: 0,
      selectedUserId: '',
    }
  },
  computed: {
    ...mapGetters('saas', ['service']),
    ...mapGetters('commonSettings', ['user_status']),
    dialogTitle() {
      if (this.isInvite) {
        return '従業員をサービスに連携招待'
      }
      return `従業員に${this.serviceName}利用中のアカウントを紐づけ`
    },
    serviceId() {
      return this.service.id
    },
    serviceName() {
      return this.service.name
    },
    subcribeAppId() {
      return this.service.subscribe_apps_id
    },
    dialogConnectButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '確認',
          variant: 'primary',
          minWidth: 124,
          size: 'md',
          func: this.integrateUser,
        },
      ]
    },
    dialogInviteButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '確認',
          variant: 'primary',
          minWidth: 124,
          size: 'md',
          func: this.inviteUser,
        },
      ]
    },
  },
  watch: {
    searchInput(newValue) {
      this.handleValueChange(newValue)
    },
    perPage() {
      this.handleValueChange()
    },
    currentPage() {
      this.handleValueChange()
    },
    reload() {
      this.searchInput = ''
      this.currentPage = 1
      this.perPage = 10
      this.reloadData()
    },
  },
  methods: {
    ...mapActions('alert', ['displayAlert']),
    selectedRow(rowData) {
      this.selectedUserId = get(rowData[0], 'id', '')
    },
    checkClass(status) {
      return status === USER_STATUS.ACTIVE
        ? 'cell__status cell__status-active'
        : 'cell__status cell__status-inactive'
    },
    getLabelStatus(status) {
      return this.user_status.find((item) => item.id === status).name
    },
    async reloadData() {
      const { items, total } = await this.reloadFunc(
        this.subcribeAppId,
        1, // backend not using this parameter, just a random number
        this.currentPage,
        this.perPage,
        this.searchInput
      )
      this.items = items
      this.total = total
    },
    async handleValueChange(keyword = this.searchInput) {
      const { items, total } = await this.reloadFunc(
        this.subcribeAppId,
        1, // backend not using this parameter, just a random number
        this.currentPage,
        this.perPage,
        keyword
      )
      this.items = items
      this.total = total
    },
    async integrateUser() {
      if (this.selectedSaasId && this.selectedUserId) {
        const { success, message } = await saasCommonService.integrateUser(
          this.subcribeAppId,
          this.selectedSaasId,
          this.selectedUserId
        )
        if (!success) {
          this.displayAlert({
            type: ALERT_TYPE.ERROR,
            messages: message,
          })
          return
        }
        this.displayAlert({
          type: ALERT_TYPE.SUCCESS,
          messages: MESSAGES.SAAS_CONNECT.LK08,
        })
      }
      this.refreshAccountList()
      this.$bvModal.hide(this.dialogId)
    },
    async inviteUser() {
      if (this.selectedUserId) {
        await this.inviteFunc(this.selectedUserId)
      }
    },
    rowClass(item) {
      if (this.isInvite && item.status !== MEMBER_STATUS.ACTIVE) {
        return 'Table__row InviteModal__row--disabled'
      }
      return 'Table__row InviteModal__row'
    },
    resetValue() {
      this.selectedUserId = ''
    },
  },
}
</script>
