<template>
  <div v-if="dataReady || connected" class="SaasConnection">
    <div class="SaasConnection__left-items">
      <div class="image">
        <img :src="src || defaultCompanyLogo" alt="image-service" />
      </div>
      <div class="detail">
        <div v-if="connected" class="detail__connection font-size-12">
          <div class="detail__connection--date">
            <span class="fw-bold">登録日時：</span>
            <span>{{ connectedDate }}</span>
          </div>
          <div class="detail__connection--total">
            <span class="fw-bold">アカウント数：</span>
            <span>{{ connectedTotal }}</span>
          </div>
        </div>
        <div class="detail__usage font-size-12">{{ usage }}</div>
      </div>
    </div>
    <div v-if="!maximumExceeded" class="SaasConnection__right-items">
      <c-button
        v-if="!connected"
        class="SaasConnection__right-items"
        title="連携"
        :min-width="124"
        variant="primary"
        :func="connectFunc"
        size="md"
      />
      <c-button
        v-else
        title="連携削除"
        :min-width="124"
        variant="danger"
        :func="disconnectFunc"
        size="md"
        class="SaasConnection__right-items"
      />
    </div>
    <div v-else class="SaasConnection__right-items">
      <ToolTip :content="maximumExceededText" :min-width="300" left="-20" arrow-left="78">
        <c-button
          class="SaasConnection__right-items btn-light"
          title="連携"
          :min-width="124"
          variant="light"
          :disabled="true"
          size="md"
        />
      </ToolTip>
    </div>
  </div>
</template>
<script>
import CButton from '@/components/commons/common-button'
import { DEFAULT_COMPANY_LOGO } from '@/utils/constants'
import ToolTip from '@/components/commons/common-tooltips'
import { mapActions, mapGetters } from 'vuex'
import { MESSAGES } from '@/utils/messages'
import { saasCommonService } from '@/services/saas-service/saas-common-service'
import { get } from 'lodash-es'
export default {
  name: 'SaasConnectionInfo',
  components: { CButton, ToolTip },
  props: {
    src: {
      type: String,
      required: true,
    },
    usage: {
      type: String,
      default: '',
    },
    connected: {
      type: Boolean,
      default: false,
    },
    connectFunc: {
      type: Function,
      default: () => {
        return null
      },
    },
    disconnectFunc: {
      type: Function,
      default: () => {
        return null
      },
    },
    connectedDate: {
      type: String,
      default: '2019年10月3日',
    },
    connectedTotal: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dataReady: false,
      defaultCompanyLogo: DEFAULT_COMPANY_LOGO,
      maximumExceededText: MESSAGES.SAAS_CONNECT.LK12,
    }
  },
  computed: {
    ...mapGetters('saas', ['package']),
    maximumExceeded() {
      return Number(this.package) <= 0 && !this.connected
    },
  },
  watch: {
    async connected() {
      await this.reloadPackage()
    },
  },
  async created() {
    this.dataReady = false
    await this.reloadPackage()
    this.dataReady = true
  },
  methods: {
    ...mapActions('saas', ['setPackage']),
    async reloadPackage() {
      const appConnected = await saasCommonService.getSaaSConnected()
      this.setPackage(get(appConnected, 'data.package', 0) - get(appConnected, 'data.app', 0))
    },
  },
}
</script>
<style lang="scss">
.SaasConnection {
  display: flex;
  padding: 14px 11px 20px 11px;
  background-color: white;
  justify-content: space-between;
  white-space: nowrap;
  min-width: 810px;
  &__left-items {
    display: flex;
    .image {
      width: 100px;
      height: 100px;
      border: 1px solid #eff2f7;
      border-radius: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 70px;
        height: 70px;
      }
    }
    .detail {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      padding: 20px;
      justify-content: space-between;
      span {
        margin-right: 40px;
      }
      &__connection {
        display: flex;
      }
      &__usage {
        margin-top: auto;
      }
    }
  }
  &__right-items {
    margin-top: auto;
  }
}
</style>
