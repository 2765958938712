<template>
  <c-modal :id="id" :buttons="dialogButtons">
    <div class="text-center font-size-16">連携を解除してもよろしいですか?</div>
  </c-modal>
</template>
<script>
import CModal from '@/components/commons/common-modal'

export default {
  name: 'DisconnectSaaSConfirmation',
  components: { CModal },
  props: {
    id: {
      type: String,
      required: true,
    },
    confirmDisconnectFunc: {
      type: Function,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      dialogButtons: [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '確認',
          variant: 'danger',
          minWidth: 118,
          size: 'md',
          func: this.confirmDisconnectFunc,
        },
      ],
    }
  },
}
</script>
