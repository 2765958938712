<template>
  <div class="Table">
    <!--  table toolbar  -->
    <div class="mb-4 font-size-16">サービス利用中のアカウント</div>
    <div class="Table__toolbar">
      <div class="Table__toolbar-search">
        <search-input v-if="hasSearch" :value.sync="searchInput" />
        <slot name="left" />
      </div>
      <div class="Table__toolbar-buttons">
        <slot name="right" />
        <b-button
          :disabled="!dialogState.FETCH"
          variant="light"
          class="m-1"
          @click="handleFetchData"
          >取り込む</b-button
        >
        <ToolTip
          content="このSaaSではユーザーの削除が出来ません。"
          :min-width="300"
          :disabled="dialogState.DELETE"
        >
          <b-button
            :disabled="!dialogState.DELETE"
            variant="light"
            class="m-1"
            @click="showDeleteDialog"
            >削除</b-button
          >
        </ToolTip>
        <ToolTip
          content="このSaaSではユーザーの作成が出来ません。"
          :min-width="300"
          :disabled="dialogState.CREATE"
        >
          <b-button
            :disabled="!dialogState.CREATE"
            variant="light"
            class="m-1"
            @click="showAddSaasAccountDialog"
            >作成</b-button
          >
        </ToolTip>
        <ToolTip
          content="このSaaSではユーザーの招待が出来ません。"
          :min-width="300"
          :disabled="dialogState.INVITE"
        >
          <b-button
            :disabled="!dialogState.INVITE"
            variant="light"
            class="m-1"
            @click="showInviteDialog"
            >招待</b-button
          >
        </ToolTip>
        <ToolTip
          content="このSaaSでは追加(手動)が出来ません。"
          :min-width="260"
          :disabled="dialogState.ADD"
        >
          <b-button :disabled="!dialogState.ADD" variant="light" class="m-1" @click="showAddDialog"
            >追加（手動）</b-button
          >
        </ToolTip>
      </div>
    </div>
    <!--    table content  -->
    <div class="table-responsive">
      <b-table
        ref="account-table"
        :items="items"
        :fields="fields"
        sort-by="external_id"
        thead-class="Table__header"
        tbody-tr-class="Table__row"
        hover
      >
        <template #head()="data">
          <div v-if="!!data.label" class="header">
            <div class="header--value">
              {{ data.label }}
            </div>
            <div v-if="data.field.sortable" class="header--sort">
              <SortIcon />
            </div>
          </div>
        </template>

        <template #cell(integrate_info_user_code)="data">
          {{ data.value ? data.value : DISABLE_LABEL }}
        </template>

        <template #cell(integrate_info_name)="data">
          <div class="text-info detail-info">
            <span
              v-if="data.item.integrate === INTEGRATE_STATUS.NOT_CONNECTED"
              @click="showConnectDialog(data.item.id)"
            >
              従業員に紐づけ
            </span>
            <span
              v-if="
                data.item.integrate === INTEGRATE_STATUS.AUTO_CONNECTED ||
                data.item.integrate === INTEGRATE_STATUS.MANUAL_CONNECTED
              "
              @click="showDetailDialog(data.item['integrate_info_id'])"
            >
              {{ data.value }}
            </span>
            <img
              v-if="data.item.integrate === INTEGRATE_STATUS.MANUAL_CONNECTED"
              class="ms-2"
              src="@/assets/icons/ic_delete.svg"
              alt="delete-icon"
              @click="handlRemoveAssociate(data.item.id)"
            />
          </div>
        </template>
        <template #cell(integrate_info_department_name)="data">
          {{ data.value ? data.value : DISABLE_LABEL }}
        </template>
        <template #cell(created_at)="data">
          <div>
            {{ getDateFromString(data.value) }}
          </div>
        </template>

        <template #cell(linking)="data">
          <div>{{ getLinkingStatus(data.item.sync) }}</div>
        </template>

        <template #cell(integrate_info_status)="data">
          <div
            v-if="data.item.integrate === INTEGRATE_STATUS.NOT_CONNECTED"
            class="text-center cell__status cell__status-warning"
          >
            {{ NOT_LINKING_SAAS }}
          </div>
          <div v-else class="text-center" :class="getClassStatus(data.item)">
            {{ getStatusLabel(data.item) }}
          </div>
        </template>
      </b-table>
    </div>
    <!--   table paging   -->
    <div class="Table__paging">
      <table-paging
        :total="total"
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :options-paging="optionsPaging"
      />
    </div>
    <!--    dialog   -->
    <saas-integrate-modal
      :dialog-id="dialogConnect"
      :is-invite="false"
      :reload-func="reloadFunc"
      :refresh-account-list="reloadData"
      :reload="triggerReload"
      :selected-saas-id="selectedId"
    />
  </div>
</template>

<script>
import {
  INTEGRATE_STATUS,
  MEMBER_STATUS,
  NOT_LINKING_SAAS,
  SAAS_CONNECTION,
  SYNC_STATUS,
} from '@/utils/constants'
import SearchInput from '@/components/commons/common-search-input'
import TablePaging from '@/components/commons/common-table-paging'
import SortIcon from '@/components/sort-icon'
import { saasCommonService } from '@/services/saas-service/saas-common-service'
import SaasIntegrateModal from '@/components/modals/saas-invite-connect-modal'
import ToolTip from '@/components/commons/common-tooltips'
import { stringToDateJapan } from '@/utils/date-utils'
import { mapActions, mapGetters } from 'vuex'
import get from 'lodash-es/get'
import { flattenArray, isNotNullOrUndefined } from '@/utils/object-helpers'

export default {
  name: 'ListOfAccountUsingServiceTable',
  components: {
    SortIcon,
    TablePaging,
    SearchInput,
    SaasIntegrateModal,
    ToolTip,
  },
  props: {
    hasInvite: {
      type: Boolean,
      default: false,
    },
    hasAddAuto: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: Boolean,
      default: true,
    },
    showAddDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
    showDeleteDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
    showInviteDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
    fetchData: {
      type: Function,
      default: () => {
        return {}
      },
    },
    showAddSaasAccountDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
    dialogState: {
      type: Object,
      default: () => {
        return {}
      },
    },
    connectFields: {
      type: Array,
      default: () => {
        return []
      },
    },
    date: {
      type: String,
      required: true,
    },
    customField: {
      type: Array,
      default: () => {
        return []
      },
    },
    reloadTable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disabled: false,
      searchInput: '',
      currentPage: 1,
      perPage: 10,
      optionsPaging: [10, 50, 100],
      items: [],
      fields: saasCommonService.getServiceFields(),
      total: 0,
      dialogConnect: 'connect-id',
      connectItems: [],
      reloadFunc: saasCommonService.getListIntegrateUsers,
      triggerReload: false,
      selectedId: 0,
      INTEGRATE_STATUS: INTEGRATE_STATUS,
      DISABLE_LABEL: SAAS_CONNECTION.DISABLE_LABEL,
      NOT_LINKING_SAAS: NOT_LINKING_SAAS,
    }
  },
  computed: {
    ...mapGetters('saas', ['service', 'fetchTime']),
    ...mapGetters('commonSettings', ['user_status']),
    month() {
      if (this.date) {
        return this.date.split('-')[0]
      }
      return ''
    },
    year() {
      if (this.date) {
        return this.date.split('-')[1]
      }
      return ''
    },
  },
  watch: {
    searchInput(newValue) {
      this.reloadData(newValue)
    },
    perPage() {
      this.reloadData()
    },
    currentPage() {
      this.reloadData()
    },
    reloadTable() {
      this.reloadData(this.searchInput, false, true)
    },
  },
  async created() {
    if (this.customField.length > 0) {
      this.fields = this.customField
    }
    await this.reloadData(this.searchInput, true)
  },
  methods: {
    ...mapActions('saas', ['setFetchTime', 'setService']),
    ...mapActions('loading', ['showManualLoading', 'hideManualLoading']),
    getClassStatus(item) {
      if (item['integrate_info_status'] === MEMBER_STATUS.ACTIVE) {
        return 'cell__status cell__status-active'
      }
      return 'cell__status cell__status-inactive'
    },
    getStatusLabel(rowData) {
      if (isNotNullOrUndefined(rowData.integrate_info_status)) {
        const status = get(rowData, 'integrate_info_status')
        return this.user_status.find((item) => item.id === status).name
      }
    },
    getLinkingStatus(linkId) {
      return SYNC_STATUS.find((item) => item.id === linkId).text
    },
    showDetailDialog(employeeId) {
      this.$router.push({
        path: '/customer/employee-detail',
        name: 'employee-detail',
        params: {
          id: employeeId,
        },
      })
    },
    async showConnectDialog(id) {
      this.selectedId = id
      this.triggerReload = !this.triggerReload
      this.$bvModal.show(this.dialogConnect)
    },
    getDateFromString(value) {
      return stringToDateJapan(value)
    },
    async handleFetchData() {
      await this.fetchData()
      this.setFetchTime({
        [this.service.id]: {
          month: this.month,
          year: this.year,
        },
      })
      // force refresh
      await this.reloadData(this.searchInput, true, true)
    },

    async reloadData(keyword = this.searchInput, refresh = false, isMaster = false) {
      let year = refresh ? this.year : null
      let month = refresh ? this.month : null
      if (refresh && this.fetchTime[this.service.id]) {
        month = Number(this.fetchTime[this.service.id].month)
        year = Number(this.fetchTime[this.service.id].year)
      }
      const { items, total } = await saasCommonService.getListUserBySaas(
        this.service.id,
        month,
        year,
        this.currentPage,
        this.perPage,
        keyword,
        isMaster
      )
      this.items = flattenArray(items)
      this.total = total
      const service = await saasCommonService.getDetailSaas(this.service.id, true)
      this.setService(service)
    },
    async handlRemoveAssociate(removedId) {
      await saasCommonService.removeIntegrateUser(this.service.subscribe_apps_id, removedId)
      await this.reloadData(this.searchInput, false, true)
    },
  },
}
</script>

<style lang="scss">
.detail-info {
  cursor: pointer;
}
</style>
