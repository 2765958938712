<template>
  <div class="Table">
    <div class="table-responsive">
      <!--  table toolbar  -->
      <div class="Table__toolbar">
        <div class="Table__toolbar-label">
          <div class="font-size-16">契約情報</div>
        </div>
        <div class="Table__toolbar-buttons">
          <b-button variant="light" class="m-1" @click="showAddContractDialog()">追加</b-button>
        </div>
      </div>
      <!--    table content  -->
      <div class="table-responsive">
        <b-table
          ref="Contract-table"
          :items="items"
          :fields="fields"
          sort-by="code"
          thead-class="Table__header"
          tbody-tr-class="Table__row"
          hover
        >
          <template #head()="data">
            <div v-if="!!data.label" class="header">
              <div class="header--value">
                {{ data.label }}
              </div>
              <div v-if="data.field.sortable" class="header--sort">
                <SortIcon />
              </div>
            </div>
            <div v-else class="action">
              {{ '' }}
            </div>
          </template>
          <template #cell(type)="data">
            {{ getContractTypeLabel(data.value) }}
          </template>
          <template #cell(price)="data">
            {{ data.value + '円' }}
          </template>
          <template #cell(start_date)="data">
            <div class="cell">
              {{ getDateString(data.value) }}
            </div>
          </template>
          <template #cell(end_date)="data">
            <div class="cell">
              {{ getDateString(data.value) }}
            </div>
          </template>
        </b-table>
      </div>
      <!--    dialog   -->
      <c-modal :id="dialogId" :title="dialogTitle" :buttons="dialogButtons">
        <contract-list-modal-form
          :form-data.sync="formData"
          :mode="mode"
          :trigger-validate.sync="triggerValidate"
          :is-valid.sync="isValid"
        />
      </c-modal>
    </div>
  </div>
</template>

<script>
import SortIcon from '@/components/sort-icon'
import CModal from '@/components/commons/common-modal'
import ContractListModalForm from '@/components/forms/contract-list-modal-form'
import { saasCommonService } from '@/services/saas-service/saas-common-service'
import { ALERT_TYPE, FORM_MODE } from '@/utils/constants'
import { stringToDateMonthJapan } from '@/utils/date-utils'
import { mapActions, mapGetters } from 'vuex'
import pick from 'lodash-es/pick'
import { contractListService } from '@/services/contract-list-service'
import { MESSAGES } from '@/utils/messages'

export default {
  name: 'ContractInformationTable',
  components: { SortIcon, ContractListModalForm, CModal },
  props: {
    selectedDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialogId: 'cl-dialog',
      dialogTitle: '',
      formData: null,
      items: [],
      fields: saasCommonService.getContractFields(),
      mode: FORM_MODE.ADD,
      disable: true,
      requiredCreateKeys: ['name', 'subscribe_apps_id', 'price', 'end_date', 'start_date', 'type'],
      triggerValidate: false,
      isValid: false,
      dialogAddButtons: [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '保存',
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onAddContract,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('saas', ['service']),
    ...mapGetters('commonSettings', ['mt_contract_type']),
    month() {
      return this.selectedDate.split('-')[0]
    },
    year() {
      return this.selectedDate.split('-')[1]
    },
    dialogButtons() {
      return this.dialogAddButtons
    },
  },
  watch: {
    async selectedDate() {
      await this.initData()
    },
  },
  async mounted() {
    await this.initData()
  },
  methods: {
    ...mapActions('alert', ['displayAlert']),
    getDateString(value) {
      return stringToDateMonthJapan(value)
    },
    getContractTypeLabel(value) {
      return this.mt_contract_type.find((item) => item.id === value).name
    },
    showAddContractDialog() {
      this.formData = {}
      this.formData.subscribe_apps_id = this.service.subscribe_apps_id
      this.mode = FORM_MODE.EDIT // cause subscribe_apps_id is defined
      this.$bvModal.show(this.dialogId)
    },
    onAddContract() {
      this.triggerValidate = !this.triggerValidate
      setTimeout(async () => {
        if (this.isValid) {
          const formData = pick(this.formData, this.requiredCreateKeys)
          const { success, data } = await contractListService.createContract(formData)
          if (!success) {
            const messages = this.getErrorAlert(data)
            messages.forEach((message) => {
              this.displayAlert({
                type: ALERT_TYPE.ERROR,
                messages: message,
              })
            })
            return
          }
          this.$bvModal.hide(this.dialogId)
          this.displayAlert({
            type: ALERT_TYPE.SUCCESS,
            messages: MESSAGES.COMMON.MSG01,
          })
          // reload tables
          this.items = await saasCommonService.getSaaSContract(
            this.service.id,
            this.month,
            this.year,
            true
          )
        }
      }, 150)
    },
    getErrorAlert(data) {
      const result = []
      if (data.name) {
        result.push(MESSAGES.CUSTOMER_MT.KH02)
      }
      if (data.time_line) {
        result.push(MESSAGES.CUSTOMER_MT.KH03)
      }
      if (result.length === 0) {
        result.push(MESSAGES.COMMON.MSG15)
      }
      return result
    },
    async initData() {
      if (this.service.id) {
        this.items = await saasCommonService.getSaaSContract(this.service.id, this.month, this.year)
      }
    },
  },
}
</script>
